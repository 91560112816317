import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export const Form = ({ children, name, resetForm, ...props }) => {
  return (
    <form onSubmit={(e) => e.preventDefault()} {...props}>
      {children}
    </form>
  );
};

Form.propTypes = {
  name: PropTypes.string.isRequired,
};
