import React from 'react';

import './NoMatch.scss';

function NoMatch() {
  return (
    <div className="not-found-page">
      <h2 className="not-found-page__heading">Sorry!</h2>
    </div>
  );
}

export default NoMatch;
