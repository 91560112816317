import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { sanitizeHtml } from 'utils';

import './Field.scss';

export const Field = ({
  onChange,
  field = 'input',
  options = [],
  value,
  placeholder,
  type = 'text',
  children,
  label,
  defaultValue,
  className,
  name,
  error,
  ...props
}) => {
  const input = (
    <input
      id={name}
      name={name}
      placeholder={placeholder}
      type={type}
      value={value || defaultValue}
      onChange={onChange}
      className="form-input__text"
      {...props}
    />
  );

  const select = (
    <div className="form-input__select-wrap">
      <select className="form-input__select" onChange={onChange} name={name} value={value} id={name} {...props}>
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options &&
          options.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );

  const textarea = (
    <textarea
      id={name}
      name={name}
      className="form-input__textarea"
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      {...props}
    >
      {children}
    </textarea>
  );

  const checkbox_radio = (fieldLabel, fieldValue, fieldName) => (
    <div className={`form-input__${field}`}>
      <label>
        <input
          type={field}
          name={fieldName}
          onChange={onChange}
          value={fieldValue}
          {...props}
          checked={fieldValue ? fieldValue : ''}
        />
        <div className={`form-input__${field}-label`} dangerouslySetInnerHTML={{ __html: sanitizeHtml(fieldLabel) }} />
      </label>
    </div>
  );

  const renderCheckboxRadioGroup = () => {
    if (!!options.length) {
      return options.map((option, index) => (
        <Fragment key={index}>{checkbox_radio(option.label, option.value, option.name)}</Fragment>
      ));
    }

    return checkbox_radio(label, value, name);
  };

  const renderField = () => {
    switch (field) {
      case 'select':
        return select;
      case 'textarea':
        return textarea;
      case 'checkbox':
        return renderCheckboxRadioGroup();
      case 'radio':
        return renderCheckboxRadioGroup();
      default:
        return input;
    }
  };

  const renderLabel = () => {
    if (label && field !== 'checkbox' && field !== 'radio') {
      return (
        <label className="form-input__label" htmlFor={name}>
          {label}
        </label>
      );
    }
  };

  return (
    <div className={cn('form-input', { 'form-input_has-error': error }, className)}>
      <div className="form-input__field-wrapper">
        {renderLabel()}
        {renderField()}
      </div>
      {error && typeof error !== 'boolean' && (
        <div className="form-input__error" dangerouslySetInnerHTML={{ __html: sanitizeHtml(error) }} />
      )}
    </div>
  );
};

Field.propTypes = {
  field: PropTypes.oneOf(['input', 'select', 'checkbox', 'radio', 'textarea']),
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.objectOf, PropTypes.string, PropTypes.bool]),
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
};
