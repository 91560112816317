import React, { useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';

import finna_video from '../../../../../asset/resource/finna_video.webm';
import finna from '../../../../../asset/resource/finna.mov';
import finna_background from '../../../../../asset/resource/finna_background.png';

import { useCheckMobile } from '../../../../hooks';

import './Title.scss';

export const Title = () => {
  const isMobile = useCheckMobile({ width: 767 });
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const [isStart1, setIsStart1] = useState(false);
  const [isStart2, setIsStart2] = useState(false);

  const handleView = (inView) => {
    if (inView) {
      const video = document.getElementById('box-video');
      setTimeout(function () {
        video.play();
      }, 2000);
    }
  };

  useEffect(() => {
    let isSafari = window.navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isSafari) setIsSafariBrowser(true);
  }, [isSafariBrowser]);

  return (
    <section
      className={cn('main-title', isSafariBrowser && 'safari')}
      style={{
        backgroundImage: `linear-gradient(to left, rgba(0,0,0,0.2), rgba(0,0,0,0.6)), url(${
          isSafariBrowser ? finna_background : finna_background
        }), radial-gradient(54.68% 54.68% at 100% 100%, rgba(0, 163, 250, .3) 63.02%, rgba(56, 0, 250, 0) 100%)`,
      }}
    >
      <div className="main-title__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <h1>
            WHO IS <span>FINNA? </span>
            <br />
            <span className="main-title__small">UNDERSTAND THE USE CASE </span>
          </h1>
        </motion.div>
        <div className="main-title__heading">
          <p className={cn('main-title__description', isStart1 && 'visible')}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              onAnimationComplete={() => setIsStart1(true)}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
            >
              <strong>Meet Financial Investment Nimble Navigation Assistant (FINNA) </strong>– the use case for a
              virtual assistant developed using the SoftServe FSI Accelerator Generative AI Digital Expert ecosystem
              (GAIDEX). FINNA’s use case is a Gen AI asset management financial advisor that in a one-on-one
              conversation, helps you navigate the often-complex world of financial terminology, products, and services.
              She is a personal, trusted guide on your journey to financial success, eliminating frustration and
              uncertainty on the way. This enables her to create a tailored client profile just for you. She helps you
              to be prepared and smoothly complete obligatory client onboarding and customer due diligence. This saves
              you time and accelerates your journey towards financial prosperity.
              <br />
              <br />
              By asking simple questions of the FINNA avatar, you will discover your financial goals, learn your
              approach to risk and then define the level of assistance you need to manage your personal finances.
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              onAnimationComplete={() => setIsStart2(true)}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
            >
              <p className={cn('main-title__sub-description', isStart2 && 'visible')}>
                The FINNA use case is here to enhance and underpin your financial well-being. Whether it's saving for
                your dream house, your children's education, or a worry-free retirement, she'll guide you through your
                requests. FINNA explains investment directions and options, providing valuable insights on how to wisely
                manage your expenses. And she does it all with friendly, logical, and engaging conversations.
                <br />
                <br />
              </p>
              <br />
              <br />
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1, delay: 0.6 }}
            >
              <ScrollLink smooth spy className="main-title__cta" to="contact-us">
                Book your immersive experience
              </ScrollLink>
            </motion.div>
          </p>

          <div className={cn('main-title__inview')}>
            <InView triggerOnce onChange={handleView} threshold={0}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                onAnimationComplete={() => setIsStart2(true)}
                variants={
                  isMobile
                    ? {
                        visible: { x: 0, y: 0, opacity: 1 },
                      }
                    : {
                        visible: {
                          x: 0,
                          y: 0,
                          opacity: 1,
                        },
                        hidden: {
                          x: 300,
                          y: 200,
                          opacity: 0,
                        },
                      }
                }
                transition={{ type: 'anticipate', duration: 3 }}
              >
                <video id="box-video" className="main__video-content" width="100%" loop muted playsInline>
                  {isSafariBrowser ? (
                    <source src={finna} type="video/mp4" />
                  ) : (
                    <source src={finna_video} type="video/webm" />
                  )}
                  Your browser is not supported!
                </video>
              </motion.div>
            </InView>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Title;
