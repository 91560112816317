import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import cn from 'classnames';
import { motion } from 'framer-motion';

//Assets
import icon_1 from '../../../../../asset/resource/icon_1.png';
import icon_2 from '../../../../../asset/resource/icon_2.png';
import icon_3 from '../../../../../asset/resource/icon_3.png';
import icon_4 from '../../../../../asset/resource/icon_4.png';
import icon_5 from '../../../../../asset/resource/icon_5.png';
import icon_6 from '../../../../../asset/resource/icon_6.png';

import { useCheckMobile } from '../../../../hooks';

import './HowToUse.scss';

export const HowToUse = () => {
  const isMobile = useCheckMobile({ width: 767 });
  const [isVisible, setIsVisible] = useState([]);

  const handleViewBlocks = (inView, i) => {
    if (inView) {
      setIsVisible((prevArray) => [...prevArray, (isVisible[i] = true)]);
    }
  };

  return (
    <section id="how-to-use" className="how-to-use">
      <div className="how-to-use__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.5 }}
        >
          <span className="sub-title">HOW TO USE </span>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8, delay: 0.3 }}
        >
          <h2>
            HOW <span>FINNA</span> USE CASE
            <br /> CAN HELP YOU
          </h2>
        </motion.div>
        <div className="how-to-use__items-block">
          <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 0)} threshold={1}>
            <h3>
              Start your journey towards financial prosperity with a smooth onboarding experience guided by FINNA’s use
              case virtual shape:
            </h3>
          </InView>
          <div className="how-to-use__item">
            <div className={cn('how-to-use__item-icons', isVisible[0] && 'visible')}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.5, delay: 0.1 }}
              >
                <img src={icon_1} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.2 }}
              >
                <img src={icon_2} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.3 }}
              >
                <img src={icon_3} alt="icon image" />
              </motion.div>
            </div>
            <div className="how-to-use__item-text">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.3 }}
              >
                <p>
                  Meet FINNA and share what you want to achieve in the future. Explain information at your own pace and
                  FINNA will direct you with any clarifying questions. She will collect all the necessary information
                  required for your asset manager to start working towards your financial objectives.
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.5 }}
              >
                <p>
                  Engage FINNA in a discussion about your confidence in the world of finance and your ability to manage
                  your finances independently. Share with her your appetite for investing in high-risk instruments.
                  FINNA will assess the level of assistance you require and determine the most suitable investment
                  strategy tailored to your specific circumstances.{' '}
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.7 }}
              >
                <p>
                  Asset management advisors will then be able to examine the client profile that FINNA has developed
                  exclusively based on your information, allowing a fast start to future interactions.{' '}
                </p>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="how-to-use__items-block">
          <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 1)} threshold={1}>
            <h3>Explore and learn with FINNA</h3>
          </InView>
          <div className="how-to-use__item">
            <div className={cn('how-to-use__item-icons', isVisible[1] && 'visible')}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.2 }}
              >
                <img src={icon_4} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.4 }}
              >
                <img src={icon_5} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.6 }}
              >
                <img src={icon_6} alt="icon image" />
              </motion.div>
            </div>
            <div className="how-to-use__item-text">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.2 }}
              >
                <p>
                  Engage immediately in a discussion with FINNA about ways you can enhance your financial well-being,
                  even before deciding on savings schemes or investments in specific assets. You will be able to analyze
                  current income and expenses and discover quick and easy ways to improve your quality of life.{' '}
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.4 }}
              >
                <p>
                  Expand your knowledge by asking FINNA about a wide range of financial products, instruments, and
                  services. Enhance your financial literacy and minimize any confusion or uncertainty with a
                  personalized financial education.{' '}
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.6 }}
              >
                <p>
                  Conduct preliminary assessments of your basic investment decisions, allowing FINNA to quickly
                  calculate potential outcomes and make recommendations.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToUse;
